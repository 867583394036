import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './PublicLayout.module.css';

interface PublicLayoutProps {
  children?: ReactNode;
  header?: any;
}

export const PublicLayout = ({ children, header }: PublicLayoutProps) => {
  return (
    <div className={styles['container']}>
      <div className={cx(styles['column-1'], 'h-background--black')}>
        <div className={cx(styles['details'], 'h-fadein')}>{header()}</div>
      </div>

      <div className={cx(styles['column-2'], styles['content'], 'relative')}>
        <div className={cx(styles['body'], 'h-fadein relative')}>{children}</div>
      </div>
    </div>
  );
};

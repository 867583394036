import { PublicLayout } from '../components/Layout/PublicLayout';
import { Layout } from '../components/Layout/Layout';
import { ContactForm } from 'components/Authentication/Forms';

export default function Custom404() {
  return (
    <Layout title="Page not found">
      <PublicLayout
        header={() => (
          <div className="l-flex column align-center">
            <img alt="login icon" className="m-b--large page-icon" src="/images/icon-contact.svg" />
            <h1 className="h-color--white m-b--none">Page not found</h1>
          </div>
        )}
      >
        <h1 className="h-color--navy">Sorry, the page you were looking for could not be found. </h1>
        <p>Return to the dashboard to continue or feel free to contact us at</p>
        <ContactForm />
      </PublicLayout>
    </Layout>
  );
}
